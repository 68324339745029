require('../scss/front.scss');

import './common.js';

import '../../vendor/oviglo/tigris-content-bundle/assets/js/tigris-content-editor'
import './../../vendor/oviglo/tigris-base-bundle/assets/js/tigris-events.js'
import Gallery from 'blueimp-gallery';
import AOS  from 'aos';
require('blueimp-gallery/css/blueimp-gallery.css');
require('blueimp-gallery/css/blueimp-gallery-video.css');
import Splide from '@splidejs/splide';
require('@splidejs/splide/dist/css/splide.min.css');
import { Carousel } from 'bootstrap';
import TigrisEvents from './../../vendor/oviglo/tigris-base-bundle/assets/js/tigris-events.js'; 

window.addEventListener('load', () => {
    vueApp.mount('#app');
    document.querySelector("body").classList.remove("load");

    const navBar = document.querySelector('#main-header');
    const body = document.body;

    const scrollCeil = body.classList.contains('has-smart-header') ? 300 : 0;

    function onScroll() {

        if (window.scrollY > scrollCeil) {
            navBar.classList.add('scrolled');
        } else {

            navBar.classList.remove('scrolled');
        }
    }
    onScroll();
    window.addEventListener('scroll', onScroll);


    const decBut = document.querySelector(".go-to-bottom");
    const decSection = document.getElementById("decouvrir");
    let decOpen = false;
    const decClose = document.querySelector(".close-decouvrir");
    if (decBut != null) {
        decBut.onclick = () => {
            if (decOpen) {
                decSection.classList.remove('active');
            } else {
                decSection.classList.add('active');
            }
            decOpen = !decOpen;
        }

        decClose.onclick = () => {
            decSection.classList.remove('active');
            decOpen = false;
        };
    }

    if (null !== document.querySelector('.gallery')) {
        document.querySelectorAll('.gallery').forEach(function (element) {
            element.onclick = function (event) {
                var target = event.target || event.srcElement,
                    link = target.src ? target.parentNode : target,
                    options = {
                        index: link,
                        event: event,
                        stretchImages: true,
                        thumbnailIndicators: true,
                    },
                    links = this.getElementsByTagName('a');
                Gallery(links, options);
            };
        });
    }

    document.querySelectorAll(".nav-link:not(.dropdown-toggle)").forEach((element) => {
        element.addEventListener("click", function () {
            document.querySelector("body").classList.add("load");
        });

    });

    /* METEO */
    const weatherUrl = "https://www.prevision-meteo.ch/services/json/senones";
    let weatherEl = document.getElementById("weather");
    if (weatherEl != null) {
        let weatherD1 = weatherEl.querySelector(".j-1");
        let request = new XMLHttpRequest();
        request.open("GET", weatherUrl);
        request.onload = () => {
            let data = JSON.parse(request.response);
            // Jour 1
            let j1 = data["fcst_day_0"];
            weatherD1.querySelector(".condition").innerHTML = j1["condition"];
            weatherD1.querySelector(".temp-max").innerHTML = j1["tmax"] + "°";
            let j2 = data["fcst_day_1"];
            weatherEl.querySelector(".j-2 .temp-max").innerHTML = j2["tmax"] + "°";
            weatherEl.querySelector(".j-2 .day-name").innerHTML = j2["day_short"];
            let j3 = data["fcst_day_2"];
            weatherEl.querySelector(".j-3 .temp-max").innerHTML = j3["tmax"] + "°";
            weatherEl.querySelector(".j-3 .day-name").innerHTML = j3["day_short"];
            let j4 = data["fcst_day_3"];
            weatherEl.querySelector(".j-4 .temp-max").innerHTML = j4["tmax"] + "°";
            weatherEl.querySelector(".j-4 .day-name").innerHTML = j4["day_short"];
        };
        request.send();
    }

    // Paralax scroll
    const parallaxElements = document.querySelectorAll('.parallax-scroll');

    if (parallaxElements.length > 0) {
        parallaxElements.forEach(element => {
            element.setAttribute('data-origin-y', element.getBoundingClientRect().top + window.scrollY);
            runParallax(element);
        });
        window.addEventListener('scroll', () => {
            parallaxElements.forEach(element => {
                runParallax(element);
            });
        });
    }

    function runParallax(element) {
        let scroll = window.scrollY;
        let originYPos = parseFloat(element.getAttribute('data-origin-y'));
        let speed = parseFloat(element.getAttribute('data-speed'));
        let deltaY = parseFloat(element.getAttribute('data-delta-y'));
        element.style.transform = `translateY(${deltaY + ((originYPos - scroll) * speed)}px)`;
    }

    const smartHeaderImage = document.querySelector('#smart-header .photo img');
    if (smartHeaderImage) {
        smartHeaderImage.setAttribute('data-origin-y', smartHeaderImage.getBoundingClientRect().top + window.scrollY);
        smartHeaderImage.setAttribute('data-speed', "-0.5");
        smartHeaderImage.setAttribute('data-delta-y', "0");
        runParallax(smartHeaderImage);
        window.addEventListener('scroll', () => {
            runParallax(smartHeaderImage);
        });
    }

    AOS.init();

    // Splide
    const splides = document.querySelectorAll('.splide');
    for (const splideEl of splides) {
        new Splide(splideEl, {
            type: 'slide',
            perPage: 3,
            perMove: 3,
            breakpoints: {
                991: {
                  perPage: 1,
                  perMove: 1,
                },
            }
        }).mount();
    }

    document.addEventListener(TigrisEvents.DIALOG_CONTENT_LOADED, (e) => {
        const element = e.detail.element;
        const carousel = element.querySelector('.facebook-post-view .carousel');
        if (null !== carousel) {
            const bsCarousel = new Carousel(carousel, {
                interval: 0,
                keyboard: false,
                pause: false,
                wrap: true,
                touch: true,
            });

            element.querySelector('.carousel-control-prev').addEventListener('click', () => {
                bsCarousel.prev();
            });

            element.querySelector('.carousel-control-next').addEventListener('click', () => {
                bsCarousel.next(); 
            });
        }
    });


})
